import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTDeleteService from "../../Common/Services/DeleteService";
import { Resources } from "../../Config/datamodels/interfaces";

const deleteService = makeRESTDeleteService(
  Resources.TRANSPORT_ORDER_OFFER,
  buildRESTUrl,
  Axios,
);
export default deleteService;
