import React from "react";
import { useParams } from "react-router-dom";
import FormView from "../../../Common/Components/FormView";
import useFormViewModel from "../../viewModel/formViewModel";
import {
  TransportOrderOffer,
  Resources,
} from "../../../Config/datamodels/interfaces";
import { TransportOrderOfferType } from "../../viewModel/types";
import useValidation from "../FormScreen/useValidation";
import {
  requestInterceptor,
  responseInterceptor,
} from "../../viewModel/formInterceptors";

export default function () {
  const { id } = useParams<any>();

  const formData = useFormViewModel(
    id,
    requestInterceptor,
    responseInterceptor,
  );
  const validation = useValidation();
  return (
    <FormView<TransportOrderOffer, TransportOrderOfferType>
      shouldGoBack
      formData={formData}
      validateButtonText={"Update your transport request"}
      title={"Update Transport Request"}
      description={"Update your transport request then click edit"}
      modelName={Resources.TRANSPORT_ORDER_OFFER}
      validation={validation}
    />
  );
}
