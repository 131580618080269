import React from "react";
import Select from "react-select";
import { FormField } from "../../../../../Store/FormReducer/Fields";

export interface SelectMultiFieldProps {
  field: FormField;
  onChange: any;
  value: string[];
}

export default function SelectMultiField<Props extends SelectMultiFieldProps>({
  field,
  value,
  onChange,
}: Props) {
  return (
    <Select
      className="selectMulti"
      isMulti
      isDisabled={field.disable}
      name={field.name}
      inputId={field.name}
      options={field.choices}
      onChange={onChange}
      // @ts-ignore
      value={value}
    />
  );
}
