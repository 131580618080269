import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Owner } from "../../datamodels/interfaces";
import styles from "./Navbar.module.css";

export default function NavHeader({
  user,
  onLogout,
  children,
}: {
  user: Owner;
  onLogout: () => void;
  children: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <div className={styles.app_container}>
        {user && (
          <Navbar className={styles.bar} light expand="md">
            <NavbarBrand className={styles.brand} href="/">
              Fine Art Logistics
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavLink href="/files/FineArtLogisticsGTU.pdf">GTU</NavLink>
                <NavLink href="/files/FineArtLogisticsPrivacyPolicy.pdf">
                  Privacy Policy
                </NavLink>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    {user.email}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={onLogout}>Logout</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Navbar>
        )}
        {children}
      </div>
    </>
  );
}
