import React, { useState } from "react";
import {
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import styles from "./Filters.module.css";
import {
  Filter,
  FilterCondition,
  cloneFilterWithValue,
  cloneFilterWithCondition,
} from "../../Filters";

interface FiltersProps {
  filters: Filter[];
  onChangeFilters: (filters: Filter[]) => void;
}

interface FilterControl {
  filter: Filter;
  onChange: (filter: Filter) => void;
}

type FilterFieldProps = FilterControl;
type FilterConditionSelectorProps = FilterControl;

export function FilterField(props: FilterFieldProps) {
  const onChange = (ev: any) => {
    ev.preventDefault();
    props.onChange(cloneFilterWithValue(props.filter, ev.target.value));
  };

  return (
    <Input
      type={props.filter.inputType}
      value={props.filter.value}
      onChange={onChange}
      disabled={!props.filter.isEditable}
      data-testid={`${props.filter.name}-${props.filter.type}`}
      className="unset-h"
    >
      {props.filter.choice &&
        props.filter.choice.map((choice) => (
          <option value={choice.value}>{choice.label}</option>
        ))}
    </Input>
  );
}

export function FilterConditionSelector(props: FilterConditionSelectorProps) {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const onClick = (newCondition: FilterCondition) => (ev: any) => {
    ev.preventDefault();
    props.onChange(cloneFilterWithCondition(props.filter, newCondition));
  };

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} size="sm">
      <DropdownToggle color="none" className="text-primary">
        {props.filter.condition}
      </DropdownToggle>
      <DropdownMenu>
        {props.filter.possibleConditions.map((condition, index) => (
          <DropdownItem key={index} onClick={onClick(condition)}>
            {condition}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
}

export default function Filters(props: FiltersProps) {
  const onChange = (index: number) => (filter: Filter) => {
    const newArray = [...props.filters];
    newArray.splice(index, 1, filter);
    props.onChangeFilters(newArray);
  };

  const deleteFilter = (index: number) => (ev: any) => {
    ev.preventDefault();
    const newArray = [...props.filters];
    newArray.splice(index, 1);
    props.onChangeFilters(newArray);
  };

  return (
    <div className={styles.filters}>
      {props.filters.map((filter, index) => (
        <div key={index}>
          <div style={{ width: (index % 7) * 10 }} className={styles.spacer} />
          <div className={styles.filterwrapper}>
            <Label>{filter.display}</Label>
            <InputGroup>
              {filter.condition && (
                <InputGroupAddon
                  addonType="prepend"
                  className="input-group-text input-group-prepend"
                >
                  <FilterConditionSelector
                    filter={filter}
                    onChange={onChange(index)}
                  />
                </InputGroupAddon>
              )}
              <FilterField filter={filter} onChange={onChange(index)} />
              <InputGroupAddon addonType="append" className="input-group-text">
                <Button
                  close
                  onClick={deleteFilter(index)}
                  data-testid={`close-filter-${filter.name}`}
                />
              </InputGroupAddon>
            </InputGroup>
          </div>
        </div>
      ))}
    </div>
  );
}
