import _ from "lodash";
import { UseDispatch, UseSelector } from "../types";
import {
  LIST_ERROR,
  LIST_SET_FILTERS,
  LIST_SET_ORDER,
  LIST_SET_PAGE,
  LIST_SET_SEARCH,
  LIST_SUCCESS,
  LOADING_LIST,
} from ".";
import { Filter } from "../../Filters";
import { Model } from "../../../Config/datamodels/interfaces";
import { ListServiceResponse } from "../../Services/types";

export type UseListReducer<ModelType extends Model> = () => {
  data: ModelType[];
  count: number;
  isLoading: boolean;
  error: any;
  currentPage: number;
  currentFilters: Filter[];
  listPageSize: number;
  maxPage: number;
  currentOrderingBy: string;
  currentDescending: boolean;
  currentSearch: string;
  setPage: (page: number) => void;
  setOrdering: (orderBy: string, descending: boolean) => void;
  setSearch: (search: string) => void;
  setFilters: (filters: Filter[]) => void;
  setData: (data: ListServiceResponse<any>) => void;
  setLoading: () => void;
  setError: (err: any) => void;
};

const makeUseListReducer = (
  modelName: string,
  selectorHook: UseSelector<any>,
  dispatchHook: UseDispatch,
) => () => {
  const dispatch = dispatchHook();

  const data = selectorHook((state: any) =>
    _.get(state, `${modelName}.data.results`, []),
  );
  const count = selectorHook((state: any) =>
    _.get(state, `${modelName}.data.results`, 0),
  );
  const isLoading = selectorHook((state: any) =>
    _.get(state, `${modelName}.loading`, false),
  );
  const error = selectorHook((state: any) =>
    _.get(state, `${modelName}.error`, null),
  );
  const currentPage = selectorHook((state: any) =>
    _.get(state, `${modelName}.currentPage`, 0),
  );
  const currentFilters = selectorHook((state: any) =>
    _.get(state, `${modelName}.filters`, []),
  );
  const listPageSize = selectorHook((state: any) =>
    _.get(state, `${modelName}.pageSize`, 10),
  );
  const maxPage = selectorHook((state: any) =>
    Math.ceil(_.get(state, `${modelName}.data.count`, 0) / listPageSize),
  );
  const currentOrderingBy = selectorHook((state: any) =>
    _.get(state, `${modelName}.orderedBy`, ""),
  );
  const currentDescending = selectorHook((state: any) =>
    _.get(state, `${modelName}.descending`, false),
  );
  const currentSearch = selectorHook((state: any) =>
    _.get(state, `${modelName}.search`, ""),
  );

  const setData = (data: ListServiceResponse<any>) =>
    dispatch({ modelName, type: LIST_SUCCESS, payload: data });
  const setLoading = () => dispatch({ modelName, type: LOADING_LIST });
  const setError = (err: any) =>
    dispatch({ modelName, type: LIST_ERROR, payload: err });
  const setPage = (page: number) =>
    dispatch({ modelName, type: LIST_SET_PAGE, page });
  const setOrdering = (orderBy: string, descending: boolean) =>
    dispatch({ modelName, type: LIST_SET_ORDER, orderBy, descending });
  const setSearch = (search: string) =>
    dispatch({ modelName, type: LIST_SET_SEARCH, search });
  const setFilters = (filters: Filter[]) =>
    dispatch({ modelName, type: LIST_SET_FILTERS, filters });

  return {
    data,
    count,
    isLoading,
    error,
    currentPage,
    currentFilters,
    listPageSize,
    maxPage,
    currentOrderingBy,
    currentDescending,
    currentSearch,
    setPage,
    setOrdering,
    setSearch,
    setFilters,
    setData,
    setLoading,
    setError,
  };
};

export default makeUseListReducer;
