import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTListService from "../../Common/Services/ListService/RESTListService";
import {
  TransportOrderOffer,
  Resources,
} from "../../Config/datamodels/interfaces";

const listService = makeRESTListService<TransportOrderOffer>(
  Resources.TRANSPORT_ORDER_OFFER,
  buildRESTUrl,
  Axios,
);
export default listService;
