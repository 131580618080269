import React from "react";
import { UncontrolledAlert } from "reactstrap";
import styles from "./ErrorHandler.module.css";

interface props {
  children: string;
}

export default function ErrorHandler({ children }: props) {
  if (children) {
    return (
      <div className={styles.container}>
        <UncontrolledAlert color="danger">{children}</UncontrolledAlert>
      </div>
    );
  }

  return <div />;
}
