import { Model } from "../../Config/datamodels/interfaces";
import { DetailService, ToastService } from "../Services/types";
import { UseDetailReducer } from "../Store/DetailReducer/useDetailReducer";

const makeUseDetailViewModel = <ModelType extends Model>(
  detailService: DetailService<ModelType>,
  useDetailReducer: UseDetailReducer<ModelType>,
  toastService: ToastService,
) => (_id: number | string) => {
  const {
    data,
    isLoading,
    isSuccess,
    id,
    setData,
    setError,
    setIsLoading,
  } = useDetailReducer();
  const get = async () => {
    setIsLoading();
    try {
      const data = await detailService(_id);
      setData(data);
      return data;
    } catch (err) {
      setError(err);
      toastService.error("An error occured");
      return err;
    }
  };

  return { get, data, isLoading, isSuccess, id };
};

export default makeUseDetailViewModel;
