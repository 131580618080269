import _ from "lodash";
import {
  FORM_CLEAR,
  FORM_ERROR,
  FORM_INITIATE_FIELDS,
  FORM_LOADING,
  FORM_SET_FIELD,
  FORM_SUCCESS,
} from ".";
import { FieldValueType } from "../../../Config/datamodels/types";
import { UseDispatch, UseSelector } from "../types";
import { FormFieldMap } from "./Fields";

export type UseFormReducer<FieldsName extends string> = () => {
  formFields: FormFieldMap<FieldsName>;
  formIsLoading: boolean;
  formErrors: Error | null;
  formSuccess: boolean;
  setField: (
    fieldName: string,
    fieldValue: FieldValueType,
    fieldArgument?: string,
  ) => void;
  initFields: (fields: FormFieldMap<FieldsName>) => void;
  clear: () => void;
  setIsLoading: () => void;
  success: () => void;
  error: (err: Error) => void;
};

const makeUseFormReducer = <FieldsName extends string>(
  modelName: string,
  selectorHook: UseSelector<any>,
  dispatchHook: UseDispatch,
) => () => {
  const dispatch = dispatchHook();

  const formFields: FormFieldMap<FieldsName> = selectorHook((state) =>
    _.get(state, `${modelName}Form.fields`),
  );
  const formIsLoading: boolean = selectorHook((state) =>
    _.get(state, `${modelName}Form.loading`),
  );
  const formErrors: Error = selectorHook((state) =>
    _.get(state, `${modelName}Form.error`),
  );
  const formSuccess: boolean = selectorHook((state) =>
    _.get(state, `${modelName}Form.success`),
  );

  const setField = (
    fieldName: string,
    fieldValue: FieldValueType,
    fieldArgument = "value",
  ): void => {
    dispatch({
      type: FORM_SET_FIELD,
      modelName,
      name: fieldName,
      value: fieldValue,
      argument: fieldArgument,
    });
  };

  const initFields = (fields: FormFieldMap<FieldsName>) => {
    dispatch({
      type: FORM_INITIATE_FIELDS,
      modelName,
      payload: fields,
    });
  };

  const clear = () => {
    dispatch({ type: FORM_CLEAR, modelName });
  };

  const setIsLoading = () => {
    dispatch({ type: FORM_LOADING, modelName });
  };

  const success = () => {
    dispatch({ type: FORM_SUCCESS, modelName });
  };

  const error = (payload: Error) => {
    dispatch({ type: FORM_ERROR, modelName, payload });
  };

  return {
    formFields,
    formIsLoading,
    formErrors,
    formSuccess,
    setField,
    initFields,
    clear,
    setIsLoading,
    success,
    error,
  };
};

export default makeUseFormReducer;
