import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTCreateService from "../../Common/Services/CreateService";
import {
  TransportOrderOffer,
  Resources,
} from "../../Config/datamodels/interfaces";

const createService = makeRESTCreateService<TransportOrderOffer>(
  Resources.TRANSPORT_ORDER_OFFER,
  buildRESTUrl,
  Axios,
);
export default createService;
