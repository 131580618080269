import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Path } from "./utils";

import TransportOrderOfferDetailScreen from "../../transportOrderOffer/screens/DetailScreen";
import TransportOrderOfferFormScreen from "../../transportOrderOffer/screens/FormScreen";
import TransportOrderOfferListScreen from "../../transportOrderOffer/screens/ListScreen";
import TransportOrderOfferUpdateScreen from "../../transportOrderOffer/screens/UpdateScreen";
import ProtectedRoute from "../../auth/components/ProtectedRoute";
import Login from "../../auth/screens/login";
import NavBar from "./Navbar";

export function RouterBase() {
  return (
    <Switch>
      <Route exact path={Path.LOGIN}>
        <Login />
      </Route>
      <Route exact path={Path.BASE}>
        <ProtectedRoute>
          <Redirect from={Path.BASE} to={Path.TRANSPORT_ORDER_OFFER} />
        </ProtectedRoute>
      </Route>
      <Route exact path={Path.TRANSPORT_ORDER_OFFER}>
        <ProtectedRoute>
          <TransportOrderOfferListScreen />
        </ProtectedRoute>
      </Route>
      <Route exact path={`${Path.TRANSPORT_ORDER_OFFER}/form`}>
        <ProtectedRoute>
          <TransportOrderOfferFormScreen />
        </ProtectedRoute>
      </Route>
      <Route path={`${Path.TRANSPORT_ORDER_OFFER}/:id/form`}>
        <ProtectedRoute>
          <TransportOrderOfferUpdateScreen />
        </ProtectedRoute>
      </Route>
      <Route exact path={`${Path.TRANSPORT_ORDER_OFFER}/:id`}>
        <TransportOrderOfferDetailScreen />
      </Route>
    </Switch>
  );
}

export default function Router() {
  return (
    <NavBar>
      <BrowserRouter>
        <RouterBase />
      </BrowserRouter>
    </NavBar>
  );
}
