import { Filter, InputType, FilterType } from "../../Common/Filters";

export abstract class FlagFilter extends Filter {
  constructor(display: string) {
    super("", display, display, undefined, []);
    this._value = display;
    this._isEditable = false;
  }
  public get inputType(): InputType {
    return "text";
  }
  protected get queryType(): string {
    return "";
  }
  protected get queryValue(): string {
    return "";
  }
  public get type(): FilterType {
    return FilterType.BOOLEAN;
  }
  public abstract clone(): Filter;
}

export class IsOrder extends FlagFilter {
  public get queryString(): string[] {
    return ["is_order__b", "True"];
  }
  public clone() {
    return new IsOrder(this._display);
  }
}

export class IsOffer extends FlagFilter {
  public get queryString(): string[] {
    // exact is a workaround because both IsOrder and IsOffer have the same key so they overwrite each others
    return ["is_order__exact__b", "False"];
  }
  public clone() {
    return new IsOffer(this._display);
  }
}
