import { Model } from "../../../Config/datamodels/interfaces";
import { RESTQueryBuilder } from "../../ApiGateway/buildRESTUrl";
import buildListQueryString from "../../ApiGateway/querystring";
import {
  HTTPGETService,
  ListServiceArguments,
  ListServiceResponse,
} from "../types";

const makeRESTListService = <ModelType extends Model>(
  modelName: string,
  urlBuilder: RESTQueryBuilder,
  getService: HTTPGETService<ModelType>,
) => async (args: ListServiceArguments) => {
  const querystring = buildListQueryString(args);
  const url = urlBuilder({ resource: modelName, querystring });
  const response = await getService.get(url);
  return (response.data as unknown) as ListServiceResponse<ModelType>;
};

export default makeRESTListService;
