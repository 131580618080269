import React, { useEffect } from "react";
import FormView from "../../../Common/Components/FormView";
import useFormViewModel from "../../viewModel/formViewModel";
import {
  TransportOrderOffer,
  Resources,
} from "../../../Config/datamodels/interfaces";
import { TransportOrderOfferType } from "../../viewModel/types";
import useValidation from "./useValidation";
import {
  requestInterceptor,
  responseInterceptor,
} from "../../viewModel/formInterceptors";

export default function () {
  const formData = useFormViewModel(
    undefined,
    requestInterceptor,
    responseInterceptor,
  );
  const validation = useValidation();

  const changeExtraServiceUrl = (is_order: boolean) => {
    if (is_order)
      formData.setField(
        "extra_service",
        //@ts-ignore exceptional set of filters
        [{ value: "extraFilters", queryString: ["is_order__b", "True"] }],
        "extraFilters",
      );
    if (!is_order)
      formData.setField(
        "extra_service",
        //@ts-ignore exceptional set of filters
        [{ value: "extraFilters", queryString: ["is_offer__b", "True"] }],
        "extraFilters",
      );
  };

  const resetExtraService = () => {
    //@ts-ignore exceptional set of filters
    formData.setField("extra_service", [], "value");
  };

  useEffect(() => {
    if (formData?.formFields?.is_order?.value !== undefined) {
      changeExtraServiceUrl(formData?.formFields?.is_order?.value === "true");
      resetExtraService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.formFields?.is_order?.value]);
  return (
    <FormView<TransportOrderOffer, TransportOrderOfferType>
      shouldGoBack
      formData={formData}
      validateButtonText={"Register a transport order or transport offer"}
      title={"New Transport Request"}
      description={"Create a new request/proposal"}
      modelName={Resources.TRANSPORT_ORDER_OFFER}
      validation={validation}
    />
  );
}
