import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTUpdateService from "../../Common/Services/UpdateService";
import {
  TransportOrderOffer,
  Resources,
} from "../../Config/datamodels/interfaces";

const updateService = makeRESTUpdateService<TransportOrderOffer>(
  Resources.TRANSPORT_ORDER_OFFER,
  buildRESTUrl,
  Axios,
);
export default updateService;
