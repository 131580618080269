import _ from "lodash";
import React from "react";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { FormField } from "../../../../../Store/FormReducer/Fields";
import { TextFilter } from "../../../../../Filters";
import makeRESTListService from "../../../../../Services/ListService/RESTListService";
import buildRESTUrl from "../../../../../ApiGateway/buildRESTUrl";

export interface ChoiceFormField extends FormField {
  value: any;
}

export interface SelectSelectFieldProps {
  field: ChoiceFormField;
  onChange: any;
}

export default function SearchSelectField<
  Props extends SelectSelectFieldProps
>({ field, onChange }: Props) {
  async function loadOptions(inputValue: string, callback: any) {
    const textFilter = new TextFilter(field.searchOnField as string, "");
    textFilter.value = inputValue;
    const extraFilters = _.get(field, "extraFilters", []);
    const filters = [...extraFilters, textFilter];
    const listService = makeRESTListService<any>(
      field.modelName as string,
      buildRESTUrl,
      axios,
    );
    const response = await listService({
      orderBy: "id",
      descending: true,
      limit: 5,
      offset: 0,
      filters,
      search: "",
    });
    const options = response.results.map((result: any) => ({
      label: _.get(result, field.searchOnField as string),
      // if there is an id in the field provide the id
      value: _.get(result, "id")
        ? _.get(result, "id")
        : _.get(result, field.primaryKey as string),
    }));
    callback(options);
  }

  function handleOnChange(event: any) {
    if (!event) {
      event = { label: "", value: null };
    }
    onChange(event);
  }

  return (
    <div id={`select-${field.name}`}>
      <AsyncSelect
        isClearable
        value={{
          label: _.get(field, "value.label"),
          value: _.get(field, "value.value"),
        }}
        isDisabled={field.disable}
        name={field.name}
        inputId={field.name}
        onChange={(event) => handleOnChange(event)}
        cacheOptions
        // @ts-ignore
        loadOptions={_.debounce(loadOptions, 300)}
        defaultOptions
      />
    </div>
  );
}
