import { RESTQueryBuilder } from "../../ApiGateway/buildRESTUrl";
import { DeleteService, HTTPDeleteService } from "../types";

const makeRESTDeleteService = (
  modelName: string,
  urlBuilder: RESTQueryBuilder,
  deleteService: HTTPDeleteService,
): DeleteService => async (id: number | string) => {
  const url = urlBuilder({ resource: modelName, id });
  await deleteService.delete(url);
};

export default makeRESTDeleteService;
