import React from "react";
import _ from "lodash";
import styles from "./Highlight.module.css";

export default function Highlight(props: any) {
  return (
    <span
      className={`${styles.highlight} ${props.className}` || ""}
      {..._.omit(props, "className")}
    >
      {props.children}
    </span>
  );
}
