import {
  FormRequestInterceptor,
  FormResponseInterceptor,
} from "../../Common/ViewModels/makeUseFormViewModel";
import { TransportOrderOffer, File } from "../../Config/datamodels/interfaces";
import _ from "lodash";
import { remodelDatetimeToFitUpdateInput } from "../../Utils/utils";

export const requestInterceptor: FormRequestInterceptor<TransportOrderOffer> = (
  body: any,
) => {
  body.is_order = body.is_order === "true";
  body.packing_list_files = _.get(body, "packing_list_files", []).map(
    (file: any) => file.value,
  );
  body.extra_service = body.extra_service
    ? body.extra_service.map((data: any) => data.value)
    : [];
  return body;
};

export const responseInterceptor: FormResponseInterceptor<TransportOrderOffer> = (
  body: any,
) => {
  return Promise.resolve({
    start_address: body?.start_address,
    destination_address: body?.destination_address,
    is_order: `${body?.is_order}`,
    packing_list: body?.packing_list,
    packing_list_files: body?.packing_list_files?.map((file: File) => ({
      label: file.original_file_name,
      value: file.id,
    })),
    transport_departure_date: remodelDatetimeToFitUpdateInput(
      body?.transport_departure_date,
    ),
    transport_arrival_date: remodelDatetimeToFitUpdateInput(
      body?.transport_arrival_date,
    ),
    answer_date: remodelDatetimeToFitUpdateInput(body?.answer_date),
    extra_service: body.extra_service
      ? body.extra_service.map((value: string) => ({
          label: value,
          value,
        }))
      : [],
  });
};
