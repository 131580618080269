import _ from "lodash";
import { Path } from "../Router/utils";

export default function (axios: any) {
  axios.interceptors.response.use(
    function (response: any) {
      return response;
    },
    function (error: any) {
      console.log({ error });
      if (_.get(error, "response.status") === 404) {
        window.location.href = Path.TRANSPORT_ORDER_OFFER;
        alert("Page was not found or does not exist");
      }
      return Promise.reject(error);
    },
  );
}
