import makeUseDetailViewModel from "../../Common/ViewModels/makeUseDetailViewModel";
import detailService from "../services/detailService";
import { useUserDetailReducer } from "../store/detailReducer";
import toastService from "../../Common/Services/ToastService";
import { TransportOrderOffer } from "../../Config/datamodels/interfaces";

const viewModel = makeUseDetailViewModel<TransportOrderOffer>(
  detailService,
  useUserDetailReducer,
  toastService,
);
export default viewModel;
