import i18n from "i18next";

export default function (axios: any) {
  axios.interceptors.request.use(
    function (config: any) {
      const langHeader = i18n.language;
      const headers = config.headers || {};
      return {
        ...config,
        headers: { ...headers, "Accept-Language": langHeader },
      };
    },
    function (error: Error) {
      return Promise.reject(error);
    },
  );
}
