import _ from "lodash";
import { Model } from "../../../Config/datamodels/interfaces";
import { DETAIL_ERROR, DETAIL_LOADING, DETAIL_SUCCESS } from "./makeReducer";
import { UseSelector, UseDispatch } from "../types";

export type UseDetailReducer<ModelType extends Model> = () => {
  data: ModelType;
  isLoading: boolean;
  isSuccess: boolean;
  id: number;
  setData: (data: ModelType) => void;
  setError: (err: Error) => void;
  setIsLoading: () => void;
};

const selectData = (modelName: string, selectorHook: UseSelector<any>) => ({
  data: selectorHook((state) => _.get(state, `${modelName}Detail.data`)),
  isLoading: selectorHook((state) =>
    _.get(state, `${modelName}Detail.loading`),
  ),
  isSuccess: selectorHook((state) =>
    _.get(state, `${modelName}Detail.success`),
  ),
  id: selectorHook((state) => _.get(state, `${modelName}Detail.data.id`)),
});

const useActions = <ModelType extends Model>(
  modelName: string,
  dispatchHook: UseDispatch,
) => {
  const dispatch = dispatchHook();

  return {
    setData: (data: ModelType) =>
      dispatch({ modelName, type: DETAIL_SUCCESS, payload: data }),
    setError: (err: Error) =>
      dispatch({ modelName, type: DETAIL_ERROR, payload: err }),
    setIsLoading: () => dispatch({ modelName, type: DETAIL_LOADING }),
  };
};

const makeUseDetailReducer = <ModelType extends Model>(
  modelName: string,
  selectorHook: UseSelector<any>,
  dispatchHook: UseDispatch,
) => () => {
  const data = selectData(modelName, selectorHook);
  const actions = useActions(modelName, dispatchHook);

  return { ...data, ...actions };
};

export default makeUseDetailReducer;
