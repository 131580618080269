import { combineReducers } from "redux";
import { Resources } from "../../Config/datamodels/interfaces";
import auth from "../../auth/store/reducer";
import transportOrderOfferListReducer from "../../transportOrderOffer/store/listReducer";
import transportOrderOfferFormReducer from "../../transportOrderOffer/store/formReducer";
import transportOrderOfferDetailReducer from "../../transportOrderOffer/store/detailReducer";

export default combineReducers({
  auth,
  [Resources.TRANSPORT_ORDER_OFFER]: transportOrderOfferListReducer,
  [`${Resources.TRANSPORT_ORDER_OFFER}Form`]: transportOrderOfferFormReducer,
  [`${Resources.TRANSPORT_ORDER_OFFER}Detail`]: transportOrderOfferDetailReducer,
});
