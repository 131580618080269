import moment from "moment";
import i18n from "i18next";
import { TyreType, Group } from "../Config/datamodels/interfaces";

export function capitalize(string: string) {
  if (string)
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function hasPermission(userGroups: string[], permittedGroups: string[]) {
  const userGroupSet = new Set(userGroups);
  const groupIntersection = new Set(
    permittedGroups.filter((group) => userGroupSet.has(group)),
  );
  return groupIntersection.size || !permittedGroups.length;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function safeToUppercase(value: string | undefined) {
  if (value) {
    return value.toUpperCase();
  }
  return undefined;
}

export function yesNoOrUnknown(t: (s: string) => string, is?: boolean | null) {
  if (is === true) return t("Yes");
  if (is === false) return t("No");
  return t("Unknown");
}

export function goodBadOrUnknown(
  t: (s: string) => string,
  is?: boolean | null,
) {
  if (is === true) return t("Good");
  if (is === false) return t("Bad");
  return t("Unknown");
}

export function okNokOrUnknown(t: (s: string) => string, is?: boolean | null) {
  if (is === true) return t("OK");
  if (is === false) return t("NOK");
  return t("Unknown");
}

export const POSITIVE_COLOR_FEEDBACK = "#00AA00";
export const NEGATIVE_COLOR_FEEDBACK = "#EE0000";
export const NEUTRAL_COLOR_FEEDBACK = "#373A3C";

export function goodBadOrUnknownColorFeedback(is?: boolean | null) {
  if (is === true) return POSITIVE_COLOR_FEEDBACK;
  if (is === false) return NEGATIVE_COLOR_FEEDBACK;
  return NEUTRAL_COLOR_FEEDBACK;
}

export function formatDate(date: Date | string) {
  return moment(date).format("DD/MM/YYYY");
}

export const getFuelLevel = (t: (s: string) => string, value?: number) => {
  switch (value) {
    case undefined:
      return t("Not specified");
    case 1:
      return t("very low (-25%)");
    case 2:
      return t("medium (25%-50%)");
    case 3:
      return t("high (50%-75%)");
    default:
      return t("almost full (75%-100%)");
  }
};

export const getTyreTypes = (t: (s: string) => string, value?: string) => {
  switch (value) {
    case undefined:
      return t("Not specified");
    case TyreType.FOURSEASONS:
      return t("Four seasons");
    case TyreType.SUMMER:
      return t("Summer");
    case TyreType.WINTER:
      return t("Winter");
    default:
      return value;
  }
};

export function getCarcheckType(
  t: (s: string) => string,
  value?: string,
): string {
  switch (value) {
    case "exhaustive":
      return t("exhaustive");
    case "periodic":
      return t("periodic");
    case "tyres check":
      return t("tyres check");
    case "damage check":
      return t("damage check");
    case "pool check":
      return t("pool check");
    default:
      return t("exhaustive");
  }
}

export function getGroupTranslationMap() {
  const groupTraduction: any = {};
  groupTraduction[Group.DRIVERS] = i18n.t("Driver");
  groupTraduction[Group.HR_OFFICERS] = i18n.t("HrOfficer");
  groupTraduction[Group.QUOTERS] = i18n.t("Quoter");
  groupTraduction[Group.CARCHECKERS] = i18n.t("Inspector");
  groupTraduction[Group.FLEET_MANAGERS] = i18n.t("Fleet Manager");
  return groupTraduction;
}

export function getOneGroupTranslation(group: Group) {
  return getGroupTranslationMap()[group];
}

export function groupChoices() {
  return [
    { label: getOneGroupTranslation(Group.DRIVERS), value: Group.DRIVERS },
    {
      label: getOneGroupTranslation(Group.HR_OFFICERS),
      value: Group.HR_OFFICERS,
    },
    {
      label: getOneGroupTranslation(Group.CARCHECKERS),
      value: Group.CARCHECKERS,
    },
    { label: getOneGroupTranslation(Group.QUOTERS), value: Group.QUOTERS },
    {
      label: getOneGroupTranslation(Group.FLEET_MANAGERS),
      value: Group.FLEET_MANAGERS,
    },
  ];
}

export function remodelDatetimeToFitUpdateInput(datetime: string) {
  const removedLastFourChar = datetime.slice(0, -4);
  return removedLastFourChar;
}
