import { RESTQueryBuilder } from "../../Common/ApiGateway/buildRESTUrl";
import { HTTPPostService } from "../../Common/Services/types";

export type ResetPasswordService = (email: string) => Promise<void>;

const makeResetPasswordService = (
  buildRESTUrl: RESTQueryBuilder,
  postService: HTTPPostService<{}>,
) => async (email: string) => {
  const url = buildRESTUrl({ resource: "users", action: "passwordreset" });
  await postService.post(url, { email });
};

export default makeResetPasswordService;
