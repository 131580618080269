import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce/lib";
import { Model } from "../../Config/datamodels/interfaces";
import { Filter } from "../Filters";
import {
  ListService,
  DeleteService,
  ListServiceArguments,
  ListServiceResponse,
} from "../Services/types";
import { UseListReducer } from "../Store/ListReducer/useListReducer";

export type ListViewModelReturn<ModelType extends Model> = {
  data: ModelType[];
  count: number;
  isLoading: boolean;
  error: Error | undefined | null;
  currentPage: number;
  currentFilters: Filter[];
  listPageSize: number;
  maxPage: number;
  currentOrderingBy: string;
  currentDescending: boolean;
  currentSearch: string;
  setPage: (page: number) => void;
  setOrdering: (orderBy: string, descending: boolean) => void;
  setSearch: (search: string) => void;
  setFilters: (filters: Filter[]) => void;
  deleteById: (id: number | string) => void;
};

export type UseListViewModel<ModelType> = (
  requestInterceptor?: ListRequestInterceptor,
  responseInterceptor?: ListResponseInterceptor<ModelType>,
) => ListViewModelReturn<ModelType>;

export type ListRequestInterceptor = (
  args: ListServiceArguments,
) => Promise<ListServiceArguments>;
export type ListResponseInterceptor<Type> = (
  response: ListServiceResponse<Type>,
) => Promise<ListServiceResponse<Type>>;

const applyRequestInterceptor = async (
  args: ListServiceArguments,
  interceptor?: ListRequestInterceptor,
): Promise<ListServiceArguments> => {
  if (!interceptor) return args;
  return await interceptor(args);
};

const applyResponseInterceptor = async <Type>(
  data: ListServiceResponse<Type>,
  interceptor?: ListResponseInterceptor<Type>,
): Promise<ListServiceResponse<Type>> => {
  if (!interceptor) return data;
  return await interceptor(data);
};

const makeListViewModel = <ModelType extends Model>(
  listService: ListService<ModelType>,
  useListReducer: UseListReducer<ModelType>,
  deleteService?: DeleteService,
): UseListViewModel<ModelType> => (
  requestInterceptor?: ListRequestInterceptor,
  responseInterceptor?: ListResponseInterceptor<ModelType>,
) => {
  const {
    data,
    count,
    isLoading,
    error,
    currentPage,
    currentFilters,
    listPageSize,
    maxPage,
    currentOrderingBy,
    currentDescending,
    currentSearch,
    setPage,
    setOrdering,
    setSearch,
    setFilters,
    setData,
    setError,
    setLoading,
  } = useListReducer();

  const list = async () => {
    setLoading();
    try {
      const args: ListServiceArguments = await applyRequestInterceptor(
        {
          limit: listPageSize,
          offset: (currentPage - 1) * listPageSize,
          orderBy: currentOrderingBy,
          descending: currentDescending,
          search: currentSearch,
          filters: currentFilters,
        },
        requestInterceptor,
      );
      const response = await listService(args);
      const transformedResponse = await applyResponseInterceptor(
        response,
        responseInterceptor,
      );
      setData(transformedResponse);
    } catch (err) {
      setError(err);
    }
  };

  const deleteById = async (id: number | string) => {
    setLoading();
    if (deleteService) await deleteService(id);
    await list();
  };

  const debouncedList = useDebouncedCallback(list, 500).callback;
  const debouncedDeleteById = useDebouncedCallback(deleteById, 500).callback;

  useEffect(() => {
    debouncedList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    currentFilters,
    listPageSize,
    maxPage,
    currentOrderingBy,
    currentDescending,
    currentSearch,
  ]);

  return {
    data,
    count,
    isLoading,
    error,
    currentPage,
    currentFilters,
    listPageSize,
    maxPage,
    currentOrderingBy,
    currentDescending,
    currentSearch,
    setPage,
    setOrdering,
    setSearch,
    setFilters,
    deleteById: debouncedDeleteById,
  };
};

export default makeListViewModel;
