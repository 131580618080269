import axios from "axios";
import addAuthorization from "./addAuthorization";
import addLanguage from "./addLanguage";
import logoutOnExpire from "./logoutOnExpire";
import notFoundRedirect from "./notFoundRedirect";
// requests interceptors
addAuthorization(axios);
addLanguage(axios);
// response interceptors
logoutOnExpire(axios);
// 4040 interceptors:
notFoundRedirect(axios);
