import React from "react";
import { useTranslation } from "react-i18next";
import { FieldValueType } from "../../../Config/datamodels/types";
import { Model } from "../../../Config/datamodels/interfaces";
import Header from "../Header";
import FormBody from "./FormBody";
import styles from "./FormView.module.css";
import { FormViewModelReturn } from "../../ViewModels/makeUseFormViewModel";

interface FormViewProps<ModelType extends Model, FieldsName extends string> {
  formData: FormViewModelReturn<ModelType, FieldsName>;
  title: string;
  modelName: string;
  description: string;
  validateButtonText: string;
  shouldGoBack?: boolean;
  shouldHideReset?: boolean;
  afterSubmit?: () => Promise<void>;
  validation?: any;
}

export default function FormView<
  ModelType extends Model,
  FieldsName extends string
>({
  formData,
  title,
  description,
  validateButtonText,
  shouldGoBack,
  shouldHideReset,
  afterSubmit,
  validation,
  modelName,
}: FormViewProps<ModelType, FieldsName>) {
  const { t } = useTranslation();
  const { formErrors, formFields, formIsLoading, clear, setField } = formData;

  async function upsert(formValues: any) {
    formData.upsert(formValues, afterSubmit);
  }

  return (
    <div className={styles.container}>
      <Header shouldGoBack={shouldGoBack} title={title}></Header>
      <FormBody
        description={description}
        onSubmit={upsert}
        onValidate={(values: any) => validation.validateForm(values)}
        onChangeField={(fieldName: string, fieldValue: FieldValueType) =>
          setField(fieldName, fieldValue)
        }
        onClear={clear}
        fields={formFields}
        modelName={modelName}
        errors={formErrors}
        shouldHideReset={shouldHideReset}
      />
    </div>
  );
}
