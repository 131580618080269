import {
  SelectField,
  DateTimeField,
  FileUploadMulti,
  TextAreaField,
  AddressField,
  SearchSelectMultiCreateField,
} from "../../Common/Store/FormReducer/Fields";
import { Resources } from "../../Config/datamodels/interfaces";

const useFormFields = () => {
  return {
    is_order: new SelectField(
      "is_order",
      "Type of request",
      "Type of request *",
      [
        { label: "Request", value: "true" },
        { label: "Proposal", value: "false" },
      ],
      { defaultValue: "true" },
    ),
    start_address: new AddressField(
      "start_address",
      "Country + postcode",
      "Place of departure *",
      { required: false },
    ),
    destination_address: new AddressField(
      "destination_address",
      "Country + postcode",
      "Place of destination *",
      { required: false },
    ),
    packing_list_files: new FileUploadMulti(
      "packing_list_files",
      "Add files",
      "Add optional information files (.xls,.pdf,…)",
      Resources.FILES,
    ),
    transport_departure_date: new DateTimeField(
      "transport_departure_date",
      "Transport departure date",
      "date of transport's departure",
      { required: true },
    ),
    transport_arrival_date: new DateTimeField(
      "transport_arrival_date",
      "Transport arrival date",
      "date of transport's arrival",
      { required: true },
    ),
    answer_date: new DateTimeField(
      "answer_date",
      "Last day for Answer",
      "Response end date",
      { required: true },
    ),
    extra_service: new SearchSelectMultiCreateField(
      "extra_service",
      "Extra services",
      "Adding extra services ? *",
      "extraservices",
      "name",
      undefined,
      {
        extraFilters: [
          //@ts-ignore
          { value: "extraFilters", queryString: ["is_order__b", "True"] },
        ],
      },
    ),
    packing_list: new TextAreaField(
      "packing_list",
      "Packing list",
      "Optional description of your packing list",
      { required: false, wide: true },
    ),
  };
};

export default useFormFields;
