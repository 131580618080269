import { Model } from "../../../Config/datamodels/interfaces";
import { RESTQueryBuilder } from "../../ApiGateway/buildRESTUrl";
import { HTTPGETService } from "../types";

const makeRESTDetailService = <ModelType extends Model>(
  modelName: string,
  urlBuilder: RESTQueryBuilder,
  getService: HTTPGETService<ModelType>,
) => async (id: number | string) => {
  const url = urlBuilder({ resource: modelName, id });
  const response = await getService.get(url);
  return response.data;
};

export default makeRESTDetailService;
