import React from "react";
import { useHistory } from "react-router-dom";
import {
  TransportOrderOffer,
  Resources,
} from "../../../Config/datamodels/interfaces";
import DocumentLink from "../../../Common/Components/DocumentLink";
import RowOptionButton from "../../../Common/Components/RowOptionButton";
import HasUserAccess from "../../../auth/components/HasUserAccess";
import { Badge } from "reactstrap";

export default function (props: {
  transportOrderOffer: TransportOrderOffer;
  onDelete: (id: number | string) => void;
}) {
  const { transportOrderOffer } = props;
  const history = useHistory();

  const orderOffer = (is_order: boolean) => {
    if (is_order) {
      return (
        <h4>
          <Badge color="primary">Request</Badge>
        </h4>
      );
    } else {
      return (
        <h4>
          <Badge color="success">Proposal</Badge>
        </h4>
      );
    }
  };
  return (
    <tr key={`${transportOrderOffer.id}`}>
      <td>
        <DocumentLink
          resource={Resources.TRANSPORT_ORDER_OFFER}
          id={transportOrderOffer.reference}
        >
          {orderOffer(transportOrderOffer.is_order)}
        </DocumentLink>
      </td>
      <td>
        <DocumentLink
          resource={Resources.TRANSPORT_ORDER_OFFER}
          id={transportOrderOffer.reference}
        >
          {transportOrderOffer.reference}
        </DocumentLink>
      </td>
      <td>
        <DocumentLink
          resource={Resources.TRANSPORT_ORDER_OFFER}
          id={transportOrderOffer.id}
        >
          {transportOrderOffer.start_address}
        </DocumentLink>
      </td>
      <td>
        <DocumentLink
          resource={Resources.TRANSPORT_ORDER_OFFER}
          id={transportOrderOffer.id}
        >
          {transportOrderOffer.destination_address}
        </DocumentLink>
      </td>
      <td className="align-middle">
        {new Date(
          transportOrderOffer.transport_departure_date,
        ).toLocaleDateString()}
      </td>
      <td className="align-middle">
        {new Date(
          transportOrderOffer.transport_arrival_date,
        ).toLocaleDateString()}
      </td>
      <td className="align-middle">
        {new Date(transportOrderOffer.answer_date).toLocaleDateString()}
      </td>
      <td className="align-middle">
        {transportOrderOffer.extra_service.join(", ")}
      </td>
      <td className="align-middle">
        <HasUserAccess userId={transportOrderOffer.owner.id}>
          <RowOptionButton
            id={transportOrderOffer.id}
            modelName={Resources.TRANSPORT_ORDER_OFFER}
            extraOptions={[
              {
                label: "edit",
                onClick: () => {
                  history.push(
                    `/${Resources.TRANSPORT_ORDER_OFFER}/${transportOrderOffer.reference}/form`,
                  );
                },
              },
              {
                label: "delete",
                onClick: () => {
                  props.onDelete(transportOrderOffer.id);
                },
              },
            ]}
          />
        </HasUserAccess>
      </td>
    </tr>
  );
}
