import React from "react";
import "./Config/axios";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Router from "./Config/Router";
import store, { persistor } from "./Config/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-calendar/dist/Calendar.css";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router />
        <ToastContainer />
      </PersistGate>
    </Provider>
  );
}

export default App;
