export enum Path {
  LOGIN = "/login",
  LOGOUT = "/logout",
  BASE = "/",
  TRANSPORT_ORDER_OFFER = "/transportorderoffer",
}

export const existingPath = [Path.LOGIN, Path.LOGOUT, Path.BASE];

export const invalidRedirectionPath = [Path.LOGIN, Path.LOGOUT];

export const validRedirection = (redirectUrl: string): string => {
  try {
    const redirectBase = `/${redirectUrl.split("/")[1]}`;
    // @ts-ignore
    if (existingPath.indexOf(redirectBase) > -1) {
      // @ts-ignore
      if (!(invalidRedirectionPath.indexOf(redirectBase) > -1)) {
        return redirectUrl;
      }
    }
    return Path.BASE;
  } catch (error) {
    return Path.BASE;
  }
};
