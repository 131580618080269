import { Validators } from "@lemoncode/fonk";
import { createFinalFormValidation } from "@lemoncode/fonk-final-form";
import { addressFieldValidator } from "../../../Common/Components/FormView/Fields/CustomFields/AdressField/AddressFieldValidator";

const useValidation = () => {
  const validations = {
    start_address: [
      {
        validator: addressFieldValidator,
      },
    ],
    destination_address: [
      {
        validator: addressFieldValidator,
        message: "Please fill in this mandatory field",
      },
    ],
    is_order: [],
    packing_list: [
      {
        validator: Validators.required.validator,
        message: "Please fill in this mandatory field",
      },
    ],
    packing_list_files: [],
    transport_departure_date: [
      {
        validator: Validators.required.validator,
        message: "Please fill in this mandatory field",
      },
    ],
    transport_arrival_date: [
      {
        validator: Validators.required.validator,
        message: "Please fill in this mandatory field",
      },
    ],
    answer_date: [
      {
        validator: Validators.required.validator,
        message: "Please fill in this mandatory field",
      },
    ],
    extra_service: [],
  };

  //@ts-ignore
  return createFinalFormValidation({ field: validations });
};

export default useValidation;
