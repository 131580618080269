import _ from "lodash";
import React, { useEffect, useState } from "react";
import axios from "axios";
import AsyncCreatableSelect from "react-select/async-creatable";
import { FormField } from "../../../../../Store/FormReducer/Fields";
import makeRESTListService from "../../../../../Services/ListService/RESTListService";
import buildRESTUrl from "../../../../../ApiGateway/buildRESTUrl";

export interface ChoiceFormField extends FormField {
  value: any;
}

export interface SearchSelectMultiCreateFieldProps {
  field: ChoiceFormField;
  onChange: any;
}

export default function SearchSelectMultiCreateField<
  Props extends SearchSelectMultiCreateFieldProps
>({ field, onChange }: Props) {
  const [options, setOptions] = useState<any[]>([]);

  async function loadOptions(inputValue: string) {
    const listService = makeRESTListService<any>(
      field.modelName as string,
      buildRESTUrl,
      axios,
    );
    const response = await listService({
      orderBy: "sequence",
      descending: false,
      limit: 10,
      offset: 0,
      filters: _.get(field, "extraFilters", []),
      search: inputValue,
    });
    const results = response.results.map((result: any) => ({
      label: _.get(result, field.searchOnField as string),
      // if there is an id in the field provide the id
      value: _.get(result, "id")
        ? _.get(result, "id")
        : _.get(result, field.primaryKey as string),
    }));
    setOptions(results);
    return results;
  }

  useEffect(() => {
    loadOptions("");
    // @ts-ignore
  }, [field.extraFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleOnChange(event: any) {
    if (!event) {
      event = [];
    }
    onChange(event);
  }

  return (
    <div id={`select-${field.name}`}>
      <AsyncCreatableSelect
        isClearable
        value={_.get(field, "value") || []}
        isDisabled={field.disable}
        isMulti
        name={field.name}
        inputId={field.name}
        onChange={(event) => handleOnChange(event)}
        // @ts-ignore
        loadOptions={loadOptions}
        options={options}
        defaultOptions={options}
      />
    </div>
  );
}
