import _ from "lodash";
import React, { useState } from "react";
import axios from "axios";
import Select from "react-select";
import { Input, Label } from "reactstrap";
import { FormField } from "../../../../../Store/FormReducer/Fields";
import CreateFormDataService from "../../../../../Services/CreateFormDataService";
import buildRESTUrl from "../../../../../ApiGateway/buildRESTUrl";

const FILE_SIZE_LIMIT = 3 /* MO */ * 1000 /* KO */ * 1000;

export interface SelectMultiFieldProps {
  field: FormField;
  onChange: any;
  value: string[];
}

const HideDropDown = (props: any) => {
  return null;
};

export default function SelectMultiField<Props extends SelectMultiFieldProps>({
  field,
  value,
  onChange,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const createRequest = CreateFormDataService(
    field.modelName as string,
    buildRESTUrl,
    axios,
  );

  const uploadFile = (file: File) => {
    const form = new FormData();
    form.append("file", file);
    return createRequest(form);
  };

  const prepFileForUploads = (files: any) => {
    const fileList = [];
    for (let file of files) {
      fileList.push(file);
    }
    return fileList;
  };

  const onSelectFiles = async (event: any) => {
    setLoading(true);
    setError("");
    const files = _.get(event, "target.files", []);
    for (const file of files) {
      if (file.size > FILE_SIZE_LIMIT) {
        setError(`File too large: ${file.name}`);
        setLoading(false);
        return;
      }
    }
    const values = value ? value : [];
    const fileList = prepFileForUploads(files);
    try {
      const images = await Promise.all(
        fileList.map(async (file: any) => uploadFile(file)),
      );
      const new_values = images.map((image: any) => ({
        label: image.original_file_name,
        value: image.id,
      }));
      onChange([...values, ...new_values]);
    } catch (err) {
      setError("Couldn't upload your file");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Select
        components={{
          DropdownIndicator: () => <HideDropDown onChange={onChange} />,
        }}
        className="selectMulti"
        isSearchable={false}
        isMulti
        error={error}
        isLoading={loading}
        disable={true}
        maxMenuHeight={0}
        isDisabled={field.disable}
        name={field.name}
        inputId={field.name}
        options={field.choices}
        onChange={onChange}
        openMenuOnClick={false}
        onMenuOpen={() => {}}
        // @ts-ignore
        value={value}
      />
      {error && (
        <Label
          style={{
            background: "red",
            color: "white",
            width: "100%",
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {error}
        </Label>
      )}
      <Label
        style={{
          background: "#017BFF",
          color: "white",
          width: "100%",
          height: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="btn btn-default btn-file"
      >
        Add File{" "}
        <Input
          style={{ display: "none" }}
          label="add files"
          type="file"
          multiple
          value=""
          onChange={onSelectFiles}
        />
      </Label>
    </div>
  );
}
