import { useDispatch, useSelector } from "react-redux";
import detailReducer, {
  makeUseDetailReducer,
} from "../../Common/Store/DetailReducer";
import { Resources } from "../../Config/datamodels/interfaces";

export default detailReducer(Resources.TRANSPORT_ORDER_OFFER);
const useUserDetailReducer = makeUseDetailReducer(
  Resources.TRANSPORT_ORDER_OFFER,
  useSelector,
  useDispatch,
);
export { useUserDetailReducer };
