import { useDispatch, useSelector } from "react-redux";
import FormReducer from "../../Common/Store/FormReducer";
import makeUseFormReducer from "../../Common/Store/FormReducer/useFormReducer";
import { Resources } from "../../Config/datamodels/interfaces";

export default FormReducer(Resources.TRANSPORT_ORDER_OFFER);
const useFormReducer = makeUseFormReducer(
  Resources.TRANSPORT_ORDER_OFFER,
  useSelector,
  useDispatch,
);
export { useFormReducer };
