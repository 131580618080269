import _ from "lodash";
import { Filter } from "../Filters";

export const setOrdering = (
  query: URLSearchParams,
  orderBy: string,
  descending: boolean,
) => {
  if (orderBy.length) {
    if (descending) {
      query.set("ordering", `-${orderBy}`);
    } else {
      query.set("ordering", orderBy);
    }
  }
};

export const setPagination = (
  query: URLSearchParams,
  limit: number,
  offset: number,
) => {
  query.set("limit", limit.toString());
  query.set("offset", offset.toString());
};

export const setSearch = (query: URLSearchParams, search: string) => {
  if (search) {
    query.set("search", search);
  }
};

export const setFilters = (query: URLSearchParams, filters: Filter[]) => {
  filters
    .filter((filter) => !!filter.value)
    .map((filter) =>
      _.chunk(filter.queryString, 2).map((filterExpr) =>
        query.set(filterExpr[0], filterExpr[1]),
      ),
    );
};

export interface BuildListQueryStringArgs {
  limit: number;
  offset: number;
  filters: Filter[];
  orderBy: string;
  descending: boolean;
  search: string;
}

const buildListQueryString = ({
  limit,
  offset,
  filters,
  orderBy,
  descending,
  search,
}: BuildListQueryStringArgs): string => {
  const query = new URLSearchParams();
  setOrdering(query, orderBy, descending);
  setPagination(query, limit, offset);
  setSearch(query, search);
  setFilters(query, filters);
  return query.toString();
};

export default buildListQueryString;
