import { TextFilter, DateFilter } from "../../../Common/Filters";
import { IsOffer, IsOrder } from "../../viewModel/Filters";

const useFields = () => {
  const filterableFields = [
    new TextFilter("reference", "# Reference"),
    new TextFilter("start_address", "Place of departure"),
    new TextFilter("destination_address", "Place of destination"),
    new DateFilter("transport_departure_date", "Transport departure date"),
    new DateFilter("transport_arrival_date", "Transport arrival date"),
    new DateFilter("answer_date", "Last day for Answer"),
    new IsOrder("Requests"),
    new IsOffer("Proposals"),
  ];

  const orderableFields = [
    { name: "", display: "None" },
    { name: "start_address", display: "Place of departure" },
    { name: "transport_departure_date", display: "Transport date" },
    { name: "answer_date", display: "Answer date" },
  ];

  return { filterableFields, orderableFields };
};

export default useFields;
