import { useDispatch, useSelector } from "react-redux";
import ListReducer from "../../Common/Store/ListReducer";
import makeUseListReducer from "../../Common/Store/ListReducer/useListReducer";
import { Resources } from "../../Config/datamodels/interfaces";

const testRessourceListReduce = ListReducer(Resources.TRANSPORT_ORDER_OFFER);

export default testRessourceListReduce;

const useListReducer = makeUseListReducer(
  Resources.TRANSPORT_ORDER_OFFER,
  useSelector,
  useDispatch,
);
export { useListReducer };
