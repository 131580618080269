import { SEPARATOR } from "./index";

const validatorType = "ADDRESS_VALIDATOR";

export const addressFieldValidator = (fieldValidatorArgs: any) => {
  const { value, message = "Not a valid address" } = fieldValidatorArgs;

  const failed = {
    succeeded: false,
    type: validatorType,
    message,
  };

  const success = {
    succeeded: true,
    type: validatorType,
    message,
  };

  if (!value) return failed;

  const split = value.split(SEPARATOR);
  if (split.length !== 3) return failed;

  const [postalCode, city, country] = value.split(SEPARATOR);

  if (!postalCode.length) return failed;
  if (!city.length) return failed;
  if (!country.length) return failed;
  if (!/[A-Z]{2}/.test(country)) return failed;

  return success;
};
