import urljoin from "url-join";

const HOST = process.env.REACT_APP_BACKEND_HOST || "http://127.0.0.1:8000";

export interface RESTUriBuilderParams {
  host?: string;
  resource: string;
  action?: string;
  id?: number | string;
  querystring?: string;
}

export type RESTQueryBuilder = (args: RESTUriBuilderParams) => string;

export default function buildRESTUrl(args: RESTUriBuilderParams) {
  const host = args.host || HOST;
  const segments = [host, "api", args.resource];
  const queryString = args.querystring ? `?${args.querystring}` : "";
  if (args.action) segments.push(args.action);
  if (args.id) segments.push(`${args.id}`);
  let path = urljoin(segments);
  if (!path.endsWith("/")) path += "/";
  return path + queryString;
}
