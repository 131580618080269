import { Model } from "../../../Config/datamodels/interfaces";
import { RESTQueryBuilder } from "../../ApiGateway/buildRESTUrl";
import { CreateService, HTTPPostService } from "../types";

const makeRESTCreateFormDataService = <ModelType extends Model>(
  modelName: string,
  urlBuilder: RESTQueryBuilder,
  postService: HTTPPostService<ModelType>,
): CreateService<ModelType> => async (body: ModelType) => {
  const header = { "Content-Type": "multipart/form-data" };
  const url = urlBuilder({ resource: modelName });
  const response = await postService.post(url, body, { headers: header });
  return response.data;
};

export default makeRESTCreateFormDataService;
