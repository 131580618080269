import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import _ from "lodash";

interface PaginatorProps {
  pageCount: number;
  currentPage: number; // 0 based
  maxPageAtOnce?: number;
  onPageClicked: (pageNumber: number) => void;
}

export function getPageRange(
  current: number,
  maxPageAtOnce: number,
  count: number,
) {
  const halfWidth = Math.ceil((maxPageAtOnce - 1) / 2);
  if (current < halfWidth + 1) {
    return _.range(1, Math.min(maxPageAtOnce, count) + 1);
  }
  if (current > count - halfWidth) {
    return _.range(Math.max(1, count - maxPageAtOnce + 1), count + 1);
  }
  const left = Math.max(1, current - halfWidth);
  const right = Math.min(count, current + halfWidth);
  return _.range(left, right + 1);
}

export default function Paginator(props: PaginatorProps) {
  const { currentPage, maxPageAtOnce = 20, pageCount, onPageClicked } = props;
  const pageRange = getPageRange(currentPage, maxPageAtOnce, pageCount);
  const previousPage = Math.max(1, currentPage - 1);
  const nextPage = Math.min(pageCount, currentPage + 1);

  const onClick = (page: number) => (ev: any) => {
    ev.preventDefault();
    onPageClicked(page);
  };

  return (
    <Pagination size="md" aria-label="Page">
      <PaginationItem>
        <PaginationLink first href="#" onClick={onClick(1)} />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink previous href="#" onClick={onClick(previousPage)} />
      </PaginationItem>
      {pageRange.map((page) => (
        <PaginationItem key={page} active={page === currentPage}>
          <PaginationLink href="#" onClick={onClick(page)}>
            {page}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem>
        <PaginationLink next href="#" onClick={onClick(nextPage)} />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink last href="#" onClick={onClick(pageCount)} />
      </PaginationItem>
    </Pagination>
  );
}
