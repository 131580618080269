import React from "react";
import useAuthViewModel from "../../../auth/viewModel/authViewModel";
import NavHeader from "./Navbar";

export default function ConnectNavBar(props: { children: React.ReactNode }) {
  const { user, logout } = useAuthViewModel();
  return (
    <NavHeader onLogout={logout} user={user}>
      {props.children}
    </NavHeader>
  );
}
