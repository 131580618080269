// *---- Imports ----
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// *---- Style ----
// *---- Components ----
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import OptionIcon from "./OptionIcon";

export interface ExtraOption {
  label: string;
  onClick: () => void;
}

export interface ComponentProps {
  id: number;
  modelName: string;
  extraOptions?: ExtraOption[];
}
export default function RowOptionButton<Props extends ComponentProps>({
  id,
  modelName,
  extraOptions,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <UncontrolledButtonDropdown direction="left" className="float-right">
      <DropdownToggle outline>
        <OptionIcon />
      </DropdownToggle>
      <DropdownMenu>
        {!extraOptions && (
          <>
            <DropdownItem
              onClick={() => history.push(`/${modelName}/${id}/form`)}
            >
              {t("Edit")}
            </DropdownItem>
          </>
        )}
        {extraOptions?.map((option, index) => (
          <DropdownItem key={index} onClick={() => option.onClick()}>
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
}
