import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { createLogger } from "redux-logger";
import { createBlacklistFilter } from "redux-persist-transform-filter";
import reducers from "./combineReducer";

const blackList = createBlacklistFilter("auth", ["error"]);

const persistConfig = {
  key: "root",
  whitelist: ["auth", "usersForm", "vehiclesForm"],
  storage,
  transforms: [blackList],
};

const logMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV === "development",
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  applyMiddleware(logMiddleware, thunk),
);

export const persistor = persistStore(store);
export default store;

// @ts-ignore
window.store = store;
