import React from "react";
import { FormField } from "../../../../../Store/FormReducer/Fields";
import { Input } from "reactstrap";
import styles from "./AddressField.module.css";

// NB: this is a special character (Alt 0150) to avoid clash with regular hyphen
export const SEPARATOR = "–";

interface AddressField extends FormField {
  value: any;
}

interface AddressFieldWidgetProps {
  field: AddressField;
  onChange: any;
}

function AddressFieldWidget(props: AddressFieldWidgetProps) {
  const [postCode, city, country] = props.field.value
    ? props.field.value.split(SEPARATOR)
    : ["", "", ""];

  const onChangePostCode = (ev: any) => {
    props.onChange([ev.target.value, city, country].join(SEPARATOR));
  };

  const onChangeCity = (ev: any) => {
    props.onChange([postCode, ev.target.value, country].join(SEPARATOR));
  };

  const onChangeCountry = (ev: any) => {
    props.onChange(
      [postCode, city, ev.target.value.toUpperCase()].join(SEPARATOR),
    );
  };

  return (
    <div className={styles.wrapper}>
      <Input
        className={[styles.input, styles.postcode].join(" ")}
        type={"text"}
        placeholder={"11560"}
        value={postCode}
        onChange={onChangePostCode}
        data-testid={props.field.name + "_postcode"}
      />
      <Input
        className={[styles.input, styles.city].join(" ")}
        type={"text"}
        placeholder={"New York"}
        value={city}
        onChange={onChangeCity}
        data-testid={props.field.name + "_city"}
      />
      <Input
        className={[styles.input, styles.country].join(" ")}
        type={"text"}
        placeholder={"US"}
        value={country}
        onChange={onChangeCountry}
        data-testid={props.field.name + "_country"}
      />
    </div>
  );
}

export default AddressFieldWidget;
