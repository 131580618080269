// *---- Imports ----
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import Card from "../../../Common/Components/Card";
import DocumentScreenBody from "../../../Common/Components/DocumentScreenBody";
import ElementWrapper from "../../../Common/Components/ElementWrapper";
import Highlight from "../../../Common/Components/Highlight";
import KeyValueText from "../../../Common/Components/KeyValueText";
import useDetailViewModel from "../../viewModel/detailViewModel";
import moment from "moment";

// *---- Style ----
import style from "./DetailScreen.module.css";
import Header from "../../../Common/Components/Header";
import { TransportOrderOffer } from "../../../Config/datamodels/interfaces";

function createMailtoLink(data: TransportOrderOffer) {
  const subject = `Transport Order/Offer # ${data.reference}`;
  const url = window.location.origin + "/transportorderoffer/" + data.reference;
  const body = `About transport # ${data.reference} %0A%0A ${url}`;
  return "mailto:" + data.owner.email + "?subject=" + subject + "&body=" + body;
}

const formatDate = (date: string) => moment(date).format("DD-MM-YYYY");

export interface DetailScreenProps {}
export default function DetailScreen(props: DetailScreenProps) {
  const { id } = useParams<any>();
  const { get, isLoading, data } = useDetailViewModel(id);

  useEffect(() => {
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || !data) return <Spinner />;

  return (
    <div className={style.wrapper}>
      <Header shouldGoBack title={"Transport Detail"}>
        <></>
      </Header>
      <DocumentScreenBody>
        <ElementWrapper title="Detail">
          <Card className={style.row}>
            <KeyValueText title="Reference">{data.reference}</KeyValueText>
            <KeyValueText title="Type">
              {data.is_order ? "Request" : "Proposal"}
            </KeyValueText>
            <KeyValueText title="From">{data.start_address}</KeyValueText>
            <KeyValueText title="To">{data.destination_address}</KeyValueText>
          </Card>
        </ElementWrapper>
        <ElementWrapper title="Dates">
          <Card className={style.row}>
            <KeyValueText title="Transport departure">
              {formatDate(data.transport_departure_date)}
            </KeyValueText>
            <KeyValueText title="Transport arrival">
              {formatDate(data.transport_arrival_date)}
            </KeyValueText>
            <KeyValueText title="Answer before">
              {formatDate(data.answer_date)}
            </KeyValueText>
          </Card>
        </ElementWrapper>
        <ElementWrapper title="Contact">
          <Card className={style.row}>
            <KeyValueText title="name">
              {data.owner.first_name || ""} {data.owner.last_name}
            </KeyValueText>
            <KeyValueText title="phone">
              <a href={"tel:" + data.owner.phone}>{data.owner.phone}</a>
            </KeyValueText>
            <KeyValueText title="email">
              <a href={createMailtoLink(data)}>{data.owner.email}</a>
            </KeyValueText>
            <KeyValueText title="company">
              {data.owner.company_name}
            </KeyValueText>
          </Card>
        </ElementWrapper>
        <ElementWrapper title="Packing">
          <Card className={style.row}>
            <KeyValueText title="description">
              {data.packing_list
                ? data.packing_list.split("\n").map((part) => (
                    <p>
                      <Highlight>{part}</Highlight>
                    </p>
                  ))
                : ""}
            </KeyValueText>
            <KeyValueText title="files">
              <ul>
                {data.packing_list_files.map((item, index) => (
                  <li>
                    <a
                      key={index}
                      href={item.file}
                      target="_blank"
                      rel="noreferrer"
                      download="1"
                    >
                      {item.original_file_name}
                    </a>
                  </li>
                ))}
              </ul>
            </KeyValueText>
          </Card>
        </ElementWrapper>
        <ElementWrapper title="Extra services">
          <Card className={style.row}>
            <ul>
              {data.extra_service.map((service) => (
                <li>
                  <p className={style.larger}>{service}</p>
                </li>
              ))}
            </ul>
          </Card>
        </ElementWrapper>
      </DocumentScreenBody>
    </div>
  );
}
