import makeListViewModel from "../../Common/ViewModels/makeUseListViewModel";
import { TransportOrderOffer } from "../../Config/datamodels/interfaces";
import { useListReducer } from "../store/listReducer";
import listService from "../services/listService";
import deleteService from "../services/deleteService";

const listViewModel = makeListViewModel<TransportOrderOffer>(
  listService,
  useListReducer,
  deleteService,
);
export default listViewModel;
