export const types = {
  AUTH_LOGIN: "auth_login",
  AUTH_LOADING: "auth_loading",
  AUTH_ERROR: "auth_error",
  AUTH_SUCCESS: "auth_success",
  AUTH_GET_USER: "auth_get_user",
  AUTH_SET_ACTIVATION_TOKEN: "auth_set_activate_token",
  AUTH_LOGOUT: "auth_logout",
  AUTH_SET_REDIRECT_URL: "auth_set_redirect_url",
  AUTH_SET_LANGUAGE: "auth_set_language",
};

const initialState = {
  user: null,
  loading: false,
  success: false,
  error: false,
  jwtToken: null,
  activationToken: null,
  redirectUrl: null,
  language: "en",
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.AUTH_SET_LANGUAGE:
      return { ...state, language: action.payload };
    case types.AUTH_LOGIN:
      return {
        ...state,
        jwtToken: action.payload,
        loading: false,
        error: false,
        success: true,
      };

    case types.AUTH_GET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: false,
        success: true,
      };
    case types.AUTH_LOGOUT:
      return { ...initialState };

    case types.AUTH_SET_ACTIVATION_TOKEN:
      return {
        ...state,
        activationToken: action.payload,
      };

    case types.AUTH_SET_REDIRECT_URL:
      return { ...state, redirectUrl: action.payload };

    case types.AUTH_LOADING:
      return { ...state, loading: true, error: false, success: false };

    case types.AUTH_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
    case types.AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
};
