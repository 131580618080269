import React from "react";
import useAuthViewModel from "../../viewModel/authViewModel";

export interface HasUserAccessProps {
  userId: number;
  children: JSX.Element[] | JSX.Element | string;
}

export default function HasUserAccess(props: HasUserAccessProps) {
  const { user } = useAuthViewModel();
  if (user.id === props.userId) {
    return <>{props.children}</>;
  } else {
    return null;
  }
}
