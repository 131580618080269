import store from "../store";
import _ from "lodash";

export default function (axios: any) {
  axios.interceptors.request.use(
    function (config: any) {
      // @ts-ignore
      const token = _.get(store.getState(), "auth.jwtToken");
      if (token) {
        const authorization = `Bearer ${token}`;

        const headers = config.headers || {};
        return {
          ...config,
          headers: { ...headers, Authorization: authorization },
        };
      } else {
        return config;
      }
    },
    function (error: Error) {
      return Promise.reject(error);
    },
  );
}
