import React from "react";
import styles from "./Header.module.css";
import BackButton from "../BackButton";

interface FormHeaderProps {
  title: string;
  children?: JSX.Element | JSX.Element[];
  shouldGoBack?: boolean;
}

export default function Header<Props extends FormHeaderProps>(props: Props) {
  return (
    <div className={styles.container}>
      {props.shouldGoBack && (
        <div className={styles.goBack}>
          <BackButton />
        </div>
      )}
      <h1 className={styles.title}>{props.title}</h1>
      <div className={styles.children}>{props.children}</div>
    </div>
  );
}
