import {
  FieldValueType,
  FieldArgumentType,
} from "../../../Config/datamodels/types";

export type FormValidationMap<FieldsName extends string> = {
  [key in FieldsName]: FormValidationField[];
};

export interface FormValidationField {
  validator: any;
  message: string;
  customArgs?: any;
}

type FieldError = string[];

export interface FieldErrors {
  [key: string]: FieldErrors | FieldError;
}

export const FORM_INITIATE_FIELDS = "form_initiate_fields";
export const FORM_SET_FIELD = "form_set_field";
export const FORM_LOADING = "form_loading";
export const FORM_SUCCESS = "form_success";
export const FORM_ERROR = "form_error";
export const FORM_CLEAR = "form_clear";
export const FORM_CLEAR_ALL = "clear_all_forms";

export interface FormActionBase {
  modelName: string;
}

export interface formInitiateFields extends FormActionBase {
  type: typeof FORM_INITIATE_FIELDS;
  // @ts-ignore due to  generic passing (will become a mess)
  payload: FormFieldMap;
}

export interface formSetField extends FormActionBase {
  type: typeof FORM_SET_FIELD;
  name: string;
  argument: FieldArgumentType;
  value: FieldValueType;
}

export interface formLoading extends FormActionBase {
  type: typeof FORM_LOADING;
}

export interface formSuccess extends FormActionBase {
  type: typeof FORM_SUCCESS;
}

export interface formError extends FormActionBase {
  type: typeof FORM_ERROR;
  payload: Error;
}

export interface formClear extends FormActionBase {
  type: typeof FORM_CLEAR;
}

export interface formClearAll extends FormActionBase {
  type: typeof FORM_CLEAR_ALL;
}

export type FormAction =
  | formInitiateFields
  | formSetField
  | formLoading
  | formSuccess
  | formSuccess
  | formError
  | formClear
  | formClearAll;

export interface FormState {
  // @ts-ignore due to  generic passing (will become a mess)
  fields: FormFieldMap;
  success: boolean;
  error: Error | null;
  loading: boolean;
}

export const initialState: FormState = {
  fields: null,
  success: false,
  error: null,
  loading: false,
};

export default (modelName: string) => (
  state: FormState = initialState,
  action: FormAction,
) => {
  if (action.type === FORM_CLEAR_ALL) {
    return initialState;
  }
  if (action.modelName !== modelName) {
    return state;
  }
  switch (action.type) {
    case FORM_INITIATE_FIELDS:
      return { ...state, fields: action.payload };

    case FORM_SET_FIELD: {
      const setFieldAction = action as formSetField;
      // couldn't find another solution than set it to any
      // open to suggestions.
      const newFields: any = { ...state.fields };
      newFields[setFieldAction.name][setFieldAction.argument] =
        setFieldAction.value;
      return { ...state, fields: { ...newFields } };
    }
    case FORM_LOADING:
      return { ...state, success: false, error: false, loading: true };
    case FORM_SUCCESS:
      return { ...state, success: true, error: false, loading: false };
    case FORM_ERROR:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
      };

    case FORM_CLEAR:
      return initialState;

    default:
      return state;
  }
};
