import React, { useState, useEffect } from "react";
import useAuthViewModel from "../../viewModel/authViewModel";
import { Button, Label, Input } from "reactstrap";
import ErrorHandler from "../../components/ErrorHandler";

// *---- Styles ----
import styles from "./login.module.css";
import { Redirect } from "react-router-dom";

// ---- Constant ----

export default function LoginScreen() {
  /**
   * Main react login component.
   *
   */

  // *---- Setup ----
  const { error, loading, login, setActivate, user } = useAuthViewModel();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function onChangeEmail(event: any) {
    setEmail(event.target.value);
  }

  function onChangePassword(event: any) {
    setPassword(event.target.value);
  }

  useEffect(() => {
    setActivate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.screen_wrapper}>
      <div className={styles.container}>
        <div className={styles.center_form_container}>
          <p className={styles.title}>{"Welcome"}</p>
          <p className={styles.description}>
            Enter your credentials to access <b>fine art logistics</b> platform.
          </p>
          <Label for="exampleEmail">Email</Label>
          <Input
            disabled={loading}
            onChange={onChangeEmail}
            className={styles.input}
            type="email"
            name="email"
            placeholder={"name@domain.be"}
            value={email}
          />
          <Label for="examplePassword">Password</Label>
          <Input
            disabled={loading}
            onChange={onChangePassword}
            className={styles.input}
            type="password"
            name="password"
            id="examplePassword"
            value={password}
            placeholder={"your secret key"}
          />
          <Button
            onClick={async () => await login({ email, password })}
            disabled={loading}
            className={styles.button}
          >
            {"Log in"}
          </Button>
          <ErrorHandler>{error}</ErrorHandler>
        </div>
      </div>
    </div>
  );
}
