// *---- Imports ----
import React from "react";
import styles from "./Card.module.css";

// *---- Style ----

// *---- Components ----

export interface CardProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export default function Card<Props extends CardProps>({
  children,
  className,
}: Props) {
  let completeClassName = styles.container;
  if (className) {
    completeClassName += ` ${className}`;
  }
  return <div className={completeClassName}>{children}</div>;
}
