export interface Model {}

export interface Rules {}
export interface Credentials {
  email: string;
  password: string;
}

export interface ConfirmPassword {
  password: string;
  confirmPassword: string;
}

export interface ResetPassword {
  email: string;
}

export type PK = Model;

export interface User extends Model {
  email: string;
  id: number;
  is_active: boolean;
  profile: Profile;
  username: string;
  first_name: string;
  last_name: string;
  groups: Group[];
  vehicle_set: Vehicle[];
  last_carcheck: Carcheck | null;
}

export interface Company extends Model {
  name: string;
}

export interface Partner extends Model {
  name: string;
  id: number;
}

export interface Image extends Model {
  xsmall: string;
  small: string;
  medium: string;
  image: string;
}

export interface Department extends Model {
  id: number;
  name: string;
  company: PK;
}

export interface VehicleType extends Model {
  name: string;
  id: number;
  company: string;
}

export interface Vehicle extends Model {
  id: number;
  created: Date;
  last_updated: Date;
  vin: string;
  plate: string;
  brand: string;
  model: string;
  year: number;
  leasing_start_date: string;
  leasing_end_date: string;
  km_contract: number;
  mileage: number;
  owning_partner: Partner;
  department: Department;
  type: VehicleType;
  picture: Image | null;
  driver: {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
  };
}

export interface Profile extends Model {
  id: number;
  company: Company | PK;
  phone?: string;
  contract_end_date?: string;
  profile_picture?: Image;
  department?: Department;
  created: string;
}

export interface PartWithDamage extends Part {
  damages: DamageInPart[];
}

export interface DamageInPart extends Damage {
  position: number;
}

export interface Carcheck extends Model {
  id: number;
  mileage: number;
  carcheck_type: string;
  dashboard_picture: Image | null;
  done_datetime: string;
  exterior_cleanliness_picture: Image | null;
  is_exterior_clean: boolean | null;
  is_exterior_clean_request: boolean;
  interior_cleanliness_picture: Image | null;
  legal_document_picture: Image | null;
  is_interior_clean: boolean | null;
  is_interior_clean_request: boolean;
  fuel_level: number;
  key_count: number;
  vehicle: Vehicle;
  is_front_left_state_good: boolean | null;
  is_front_right_state_good: boolean | null;
  is_back_left_state_good: boolean | null;
  is_back_right_state_good: boolean | null;
  front_tyres_type: string;
  back_tyres_type: string;
  damage_set: Damage[];
  inventorypresence_set?: InventoryPresence[];
  participation_set: Participation[];
  damage_per_part?: PartWithDamage[];
}

export interface CarcheckListEntry {
  id: number;
  mileage: number;
  carcheck_type: string;
  fuel_level: number;
  done_datetime: string;
  vehicle: { id: number; vin: string; plate: string };
  damage_count: number;
  budget: number;
  tyres: string;
}

export interface InventoryPresence {
  id: number;
  presence: boolean;
  inventory_item: Inventory;
}

export interface Inventory {
  category_en: string;
  category_fr: string;
  category_nl: string;
  created: Date;
  id: number;
  last_updated: Date;
  name_en: string;
  name_fr: string;
  name_nl: string;
  vehicle: number;
}

export interface Damage {
  id: number;
  created: Date;
  last_updated: Date;
  is_acceptable: boolean;
  is_repaired: boolean;
  checkly_cost_estimate: string;
  leasing_cost_estimate: string;
  carfixer_cost_estimate: string;
  part: Part;
  type: DamageType;
  original_carcheck: number;
  carcheck_presence: number[];
  pictures: Image[];
  participation: Participation[];
}

export interface DamageType {
  id: number;
  created: Date;
  last_updated: Date;
  name_fr: string;
  name_en: string;
  name_nl: string;
  car_part: number;
}

export interface Part {
  id: number;
  damagetype_set: DamageType[];
  created: Date;
  last_updated: Date;
  name_fr: string;
  name_en: string;
  name_nl: string;
  category_fr: string;
  category_en: string;
  category_nl: string;
  vehicle: number;
}

export interface Participation {
  signature: Image;
  user: User;
  comment: string;
}

export interface CarcheckRule extends Model {
  id: number;
  cron: string | null;
  cron_base_date: string | null;
  time_before_end_contract_vehicle: string | null;
  time_before_end_contract_employee: string | null;
  company: string;
  filter: string;
  last_triggered: string;
  carcheck_type: string;
  vehicle_type: VehicleType | null;
  owning_partner: Company | null;
}

export interface CarcheckRequest {
  id: number;
  user_language: string;
  created: string;
  last_updated: string;
  carcheck_type: string;
  date: string;
  vehicle: Vehicle;
  user: User;
  carcheck: number | null;
  status: Status;
}

export enum Group {
  DRIVERS = "drivers",
  HR_OFFICERS = "hr_officers",
  CARCHECKERS = "carcheckers",
  QUOTERS = "quoters",
  FLEET_MANAGERS = "fleet_managers",
  BACKOFFICE_USERS = "backoffice_users",
}

export enum TyreType {
  WINTER = "WINTER",
  SUMMER = "SUMMER",
  FOURSEASONS = "FOURSEASONS",
}

export enum Status {
  PENDING = "PENDING",
  DONE = "DONE",
  NOT_SENT = "NOT_SENT",
  OVERDUE = "OVERDUE",
}

export enum Language {
  FR = "fr",
  EN = "en",
  NL = "nl",
}

export const availableLanguages = [Language.FR, Language.EN, Language.NL];

export enum RuleTypes {
  CRON = "cron",
  END_VEHICLE_CONTRACT = "time_before_end_contract_vehicle",
  END_EMPLOYEE_CONTRACT = "time_before_end_contract_employee",
}

export interface TestResource {
  id: number;
  name: string;
  amount: number;
}

// < non-Checkly-Items >

export enum Resources {
  TRANSPORT_ORDER_OFFER = "transportorderoffer",
  FILES = "files",
}

export interface Owner {
  id: number;
  first_name: string;
  last_name: string;
  company_name: string;
  email: string;
  phone: string;
}

export interface File {
  id: number;
  created_at: string;
  update_at: string;
  owner: Owner;
  file: string;
  original_file_name: string;
}
export interface TransportOrderOffer {
  id: number;
  reference: string;
  owner: Owner;
  start_address: string;
  destination_address: string;
  is_order: boolean;
  packing_list: string;
  packing_list_files: File[];
  transport_departure_date: string;
  transport_arrival_date: string;
  answer_date: string;
  extra_service: string[];
}
