import React from "react";
import { TransportOrderOffer } from "../../../Config/datamodels/interfaces";
import ListView from "../../../Common/Components/ListView";
import { Path } from "../../../Config/Router/utils";
import ListItem from "./ListItem";
import listViewModel from "../../viewModel/listViewModel";
import useFields from "./useFields";

export default function ListScreen() {
  const listData = listViewModel();
  const { filterableFields, orderableFields } = useFields();
  const headers = [
    "Type",
    "Ref#",
    "Place of departure",
    "Place of destination",
    "Departure Date",
    "Arrival Date",
    "Answer Date",
    "extra services",
    "",
  ];
  return (
    <ListView<TransportOrderOffer>
      title={"Available Transports"}
      basePath={Path.TRANSPORT_ORDER_OFFER}
      emptyListTitle={"Transport Request List"}
      emptyListDescription={"Transport Requests List"}
      navigationFormButtonLabel={"NEW"}
      listData={listData}
      headers={headers}
      renderLine={(transportOrderOffer: TransportOrderOffer) => (
        <ListItem
          transportOrderOffer={transportOrderOffer}
          onDelete={(id: number | string) => listData.deleteById(id)}
        />
      )}
      availableFilters={filterableFields}
      orderableFields={orderableFields}
    />
  );
}
